import React, { Component } from 'react';
import ReactModal from 'react-modal';
import TextareaAutosize from 'react-textarea-autosize';

import PropTypes from 'prop-types';

import 'react-quill/dist/quill.snow.css';
import './index.css';

import Api from '../../../Services/Cloud';


const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 2
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%'
    }
};

ReactModal.setAppElement('#root');

export default class ObservationModal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onError: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            description: '',
            to_return: 'false',
            requesting: false
        }
    }


    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requesting: true });
        try {
            await Api.post('/callindications', { receiver_id: this.props.possiblePartinerId, description: this.state.description, to_return: this.state.to_return });
            this.setState({ description: "", requesting: false });
            this.props.onSuccess("Ligação registrada");
        } catch (error) {
            this.setState({ requesting: false });
            if (error.response && error.response.data) {
                if (error.response.data.userMessage) return this.props.onError(error.response.data.userMessage);
                return this.props.onError(error.response.data.message)
            }
            this.props.onError(error.message);
        }
    }

    handleClose = () => {
        this.clearForm();
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    clearForm = () => {
        this.setState({
            description: "",
            requesting: false
        })
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Cadastrar ligação</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-3 pt-2 pb-0">
                    <form id="course-form" onSubmit={e => { this.handleSubmit(e) }}>
                        <div className="form-group">
                            <label>Observações</label>
                            <TextareaAutosize
                                value={this.state.description}
                                onChange={e => { this.setState({ description: e.target.value }) }}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>Retornar ligação?</label>
                            <select
                                className="form-control"
                                name="to_return"
                                value={this.state.to_return}
                                onChange={e => { this.setState({ to_return: e.target.value }) }}
                                required
                            >
                                <option value="false">Não</option>
                                <option value="true">Sim</option>
                            </select>
                        </div>
                        <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3">
                            <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                            <button type="submit" className="btn btn-primary" disabled={this.state.requesting}>Salvar</button>
                        </div>
                    </form>
                </div>
            </ReactModal>
        )
    }
}