import React, { Component } from 'react';

import Api from '../../Services/Cloud';
import DataTable from '../../components/DataTable';
import Modal from './Modal';
import UpdateValueModal from './UpdateValueModal';
import { parseMoney, removeAcento } from '../../Util/Functions';
import Loading from '../../components/Loading';
import moment from 'moment';

import $ from 'jquery';
import { toast } from 'react-toastify';

import '../../Libs/fontawesome/css/all.css';

function getShiftName(shift = '') {
    shift = shift.toLowerCase();
    if (shift === 'matutino') return 'Matutino';
    if (shift === 'vespertino') return 'Vespertino';
    if (shift === 'noturno') return 'Noturno';
    if (shift === 'integral') return 'Integral';
    if (shift === 'segundas-manha') return 'Segundas - (Manhã)';
    if (shift === 'segundas-noite') return 'Segundas - (Noite)';
    if (shift === 'tercas-noite') return 'Terças - (Noite)';
    if (shift === 'tercas-manha') return 'Terças - (Manhã)';
    if (shift === 'tercas-tarde') return 'Terças - (Tarde)';
    if (shift === 'tercas-quintas-noite') return 'Terças e quintas - (Noite)';
    if (shift === 'quartas-sextas-noite') return 'Quartas e sextas - (Noite)';
    if (shift === 'quintas-manha') return 'Quintas - (Manhã)';
    if (shift === 'quintas-tarde') return 'Quintas - (Tarde)';
    if (shift === 'quintas-noite') return 'Quintas - (Noite)';
    if (shift === 'sextas-manha') return 'Sextas - (Manhã)';
    if (shift === 'sextas-tarde') return 'Sextas - (Tarde)';
    if (shift === 'sextas-noite') return 'Sextas - (Noite)';
    if (shift === 'quartas-manha') return 'Quartas - (Manhã)';
    if (shift === 'quartas-tarde') return 'Quartas - (Tarde)';
    if (shift === 'quartas-noite') return 'Quartas - (Noite)';
    if (shift === 'quartas-manha-tarde') return 'Quartas - (Manhã e tarde)';
    if (shift === 'segundas-tercas-manha') return 'Segundas e terças - (Manhã)';
    if (shift === 'segundas-tercas-tarde') return 'Segundas e terças - (Tarde)';
    if (shift === 'segundas-tercas-noite') return 'Segundas e terças - (Noite)';
    if (shift === 'segundas-tercas-manha-tarde') return 'Segundas e terças - (Manhã e tarde)';
    if (shift === 'sabados-manha') return 'Sábados - (Manhã)';
    if (shift === 'sabados-tarde') return 'Sábados - (Tarde)';
    if (shift === 'sabados-manha-tarde') return 'Sábados - (Manhã e tarde)';
    return 'Não definido';
}

const columns = [
    { title: "Curso", render: (data, type, row) => { if (type === 'display') { return row.course_name } else { return removeAcento(row.course_name) } } },
    { title: "Instituição", render: (data, type, row) => { if (type === 'display') { return row.institution_name.toUpperCase() + ' - ' + row.institution_city.toUpperCase() + ' - ' + row.institution_district.toUpperCase() } else { return removeAcento(row.institution_name.toUpperCase()) + ' - ' + removeAcento(row.institution_city.toUpperCase()) + ' - ' + removeAcento(row.institution_district.toUpperCase()) } } },
    { title: "Turno", render: (data, type, row) => { return getShiftName(row.shift).toUpperCase() } },
    { title: "Tipo", render: (data, type, row) => { return row.type.toUpperCase() } },
    { title: "Preço integral", render: (data, type, row) => { return parseMoney(row.full_price) } },
    { title: "Desconto", render: (data, type, row) => { return row.discount + '%' } },
    { title: "Preço final", render: (data, type, row) => { return parseMoney(row.final_price) } },
    { title: "Duração do período", render: (data, type, row) => { return row.renovation_days + ' dias' } },
    { title: "Bolsas Ofertadas/Vendidas", render: (data, type, row) => { return row.quantity_offered + '/' + row.quantity_sold } },
    { title: "Atualizado em", render: (data, type, row) => { return moment(row.updated_at).format('DD/MM/YYYY'); } },
    { title: "Ativo" },
    { title: "Expirou" },
    { title: "Ações" },
];

const columnDefs = [
    {
        targets: [-3],
        render: function (data, type, row) {
            return row.active ? 'Sim' : 'Não'
        }
    },
    {
        targets: [-2],
        render: function (data, type, row) {
            return row.expired ? 'Sim' : 'Não'
        }
    },
    {
        targets: -1,
        data: null,
        defaultContent: '<a  class=" action-button" data-toggle="modal"><i class="fas fa-sync action-icon update-scholarship"></i></span></a>'
            + '<a  class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon edit-scholarship"></i></span></a>'
            + '<a  class=" action-button"><i class="fas fa-check action-icon toggle-scholarship"></i></span></a>'
            + '<a  class=" action-button"><i class="far fa-trash-alt action-icon delete-scholarship"></i></span></a>'
    }
];


export default class Scholarships extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scholarships: [],
            loading: true,
            modalCreateOpen: false,
            showExpired: false,
            modalUpdateValueOpen: false,
            showInativas: false,
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (update) => {
        let response = await Api.get('/scholarship/list/backoffice', { params: { showExpired: this.state.showExpired, showInativas: this.state.showInativas } });
        this.setState({ scholarships: response.data.scholarships, loading: false });
        if (update === true) {
            this.table.reloadTableData(response.data.scholarships);
        }
    }

    handleSuccessCommon = (message) => {
        this.getData(true);
        toast.success(message);
    }

    handleSuccess = (message) => {
        this.toggleModal();
        this.handleSuccessCommon(message);
    }

    handleSuccessUpdateValue = (message) => {
        this.toggleUpdateValueModal();
        this.handleSuccessCommon(message);
    }

    handleError = (message) => {
        toast.error(message);
    }

    handleTableClick = (e, dataTable) => {
        let target = $(e.target);
        var current_row = target.parents('tr');
        if (current_row.hasClass('child')) {
            current_row = current_row.prev();
        }
        var data = dataTable.row(current_row).data();
        if (e.target.className.includes('delete-scholarship')) {
            if (window.confirm(`Deseja excluir a bolsa?`)) this.deleteScholarship(data.id);
        }
        else if (e.target.className.includes('toggle-scholarship')) {
            let stringMessage = `Deseja habilitar a bolsa?`;
            if (data.active === true) stringMessage = `Deseja desabilitar a bolsa?`;
            var confirm = window.confirm(stringMessage);
            if (confirm) this.toggleScholarship(data.id);
        }
        else if (e.target.className.includes('edit-scholarship')) {
            this.openScholarshipModal(data.id);
        }
        else if (e.target.className.includes('update-scholarship')) {
            this.openUpdateValueModal(data.id);
        }
    }

    openScholarshipModal = (scholarshipId) => {
        this.setState({ scholarshipId }, this.toggleModal)
    }

    openUpdateValueModal = (scholarshipId) => {
        this.setState({ scholarshipId }, this.toggleUpdateValueModal)
    }

    toggleScholarship = async (scholarshipId) => {
        try {
            await Api.put('/scholarship/toggle', { scholarship_id: scholarshipId });
            toast.success('Bolsa atualizada.')
            this.getData(true);
        } catch (error) {
            if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
            if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
            return toast.error(error.message);
        }
    }

    deleteScholarship = async (scholarshipId) => {
        try {
            await Api.delete('/scholarship', { data: { scholarship_id: scholarshipId } });
            toast.success('Bolsa excluída.')
            this.getData(true);
        } catch (error) {
            if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
            if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
            return toast.error(error.message);
        }
    }

    toggleModal = () => {
        if (this.state.modalCreateOpen) {
            this.setState({ scholarshipId: null });
        }
        this.setState({ modalCreateOpen: !this.state.modalCreateOpen })
    }

    toggleUpdateValueModal = () => {
        this.setState({ modalUpdateValueOpen: !this.state.modalUpdateValueOpen })
    }

    render() {
        if (this.state.loading) return <Loading />;
        return (
            <div className="mt-2 w-100">
                <button className="btn btn-primary mb-4" onClick={this.toggleModal}>CADASTRAR</button>
                <input
                    type="checkbox"
                    className="ml-4"
                    onChange={(e) => { this.setState({ showExpired: e.target.checked }, () => { this.getData(true) }) }}
                />
                <span> Mostrar bolsas que expiraram</span>
                <input
                    type="checkbox"
                    className="ml-4"
                    onChange={(e) => { this.setState({ showInativas: e.target.checked }, () => { this.getData(true) }) }}
                />
                <span> Mostrar somente ativas</span>
                <DataTable
                    data={this.state.scholarships}
                    columns={columns}
                    options={{
                        buttons: [],
                        responsive: true,
                        columnDefs: columnDefs,
                        destroy: true
                    }}
                    handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
                    ref={ref => this.table = ref}
                />
                <Modal
                    isOpen={this.state.modalCreateOpen}
                    shouldCloseOnEsc={true}
                    scholarshipId={this.state.scholarshipId ? this.state.scholarshipId : null}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.toggleModal}
                    onSuccess={message => { this.handleSuccess(message) }}
                    onError={message => { this.handleError(message) }}
                />
                <UpdateValueModal
                    isOpen={this.state.modalUpdateValueOpen}
                    shouldCloseOnEsc={true}
                    scholarshipId={this.state.scholarshipId}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.toggleUpdateValueModal}
                    onSuccess={message => { this.handleSuccessUpdateValue(message) }}
                    onError={message => { this.handleError(message) }}
                />
            </div>
        )
    }
}

