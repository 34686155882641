import React, { Component } from 'react';

import Api from '../../Services/Cloud';
import DataTable from '../../components/DataTable';
import Modal from './Modal';
import Loading from '../../components/Loading';

import $ from 'jquery';
import { toast } from 'react-toastify';

import '../../Libs/fontawesome/css/all.css';

const columns = [
    { title: "Pergunta", data: "question" },
    { title: "Ações" },
];

const columnDefs = [
    {
        targets: -1,
        data: null,
        defaultContent: '<a  class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon edit-question"></i></span></a>'
            + '<a  class=" action-button"><i class="far fa-trash-alt action-icon delete-question"></i></span></a>'
    }
];


export default class FAQ extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: [],
            loading: true,
            modalCreateOpen: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (update) => {
        let response = await Api.get('/faq');
        this.setState({ questions: response.data.faq, loading: false });
        if (update === true) {
            this.table.reloadTableData(response.data.faq);
        }
    }

    handleSuccess = (message) => {
        this.toggleModal();
        this.getData(true);
        toast.success(message);
    }

    handleError = (message) => {
        toast.error(message);
    }

    handleTableClick = (e, dataTable) => {
        let target = $(e.target);
        var current_row = target.parents('tr');
        if (current_row.hasClass('child')) {
            current_row = current_row.prev();
        }
        var data = dataTable.row(current_row).data();
        if (e.target.className.includes('delete-question')) {
            if (window.confirm(`Deseja excluir?`)) this.deleteQuestion(data.id);
        }
        else if (e.target.className.includes('edit-question')) {
            this.openQuestionEditModal(data.id);
        }
    }

    openQuestionEditModal = (faqId) => {
        this.setState({ faqId }, this.toggleModal)
    }

    deleteQuestion = async (faqId) => {
        try {
            await Api.delete('/faq', { data: { faq_id: faqId } });
            toast.success('Questão excluída.')
            this.getData(true);
        } catch (error) {
            if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
            if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
            return toast.error(error.message);
        }
    }

    toggleModal = () => {
        if (this.state.modalCreateOpen) {
            this.setState({ faqId: null });
        }
        this.setState({ modalCreateOpen: !this.state.modalCreateOpen })
    }

    render() {
        if (this.state.loading) return <Loading />;
        return (
            <div className="mt-2 w-100">
                <button className="btn btn-primary mb-4" onClick={this.toggleModal}>CADASTRAR</button>
                <DataTable
                    data={this.state.questions}
                    columns={columns}
                    options={{
                        buttons: [],
                        responsive: true,
                        columnDefs: columnDefs,
                        destroy: true
                    }}
                    handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
                    ref={ref => this.table = ref}
                />
                <Modal
                    isOpen={this.state.modalCreateOpen}
                    shouldCloseOnEsc={true}
                    faqId={this.state.faqId ? this.state.faqId : null}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.toggleModal}
                    onSuccess={message => { this.handleSuccess(message) }}
                    onError={message => { this.handleError(message) }}
                />
            </div>
        )
    }
}

