import React, { Component } from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';

import DatePicker from "react-datepicker";
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'

import "react-datepicker/dist/react-datepicker.css";
import 'react-quill/dist/quill.snow.css';
import './index.css';

import Api from '../../../Services/Cloud';
import moment from 'moment';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 2
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%'
    }
};

ReactModal.setAppElement('#root');

export default class Modal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onError: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            scholarships: [],
            courses: [],
            institutions: [],
            shift: "",
            type: "",
            full_price: null,
            full_price_float: null,
            discount: "",
            quantity_offered: "",
            renovation_days: "",
            register_period_start: new Date(),
            register_period_end: null,
            course_description: "Descrição do curso",
            course_id: "disabled",
            institution_id: "disabled",
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(document.getElementById('scholarship-form'));
        var scholarshipData = {};
        formData.forEach((value, key) => { scholarshipData[key] = value });
        scholarshipData.full_price = this.state.full_price_float;
        scholarshipData.register_period_start = moment(this.state.register_period_start).format('YYYY/MM/DD');
        if (this.state.register_period_end) scholarshipData.register_period_end = moment(this.state.register_period_end).format('YYYY/MM/DD');
        scholarshipData.course_description = this.state.course_description;
        scholarshipData.course_id = this.state.course_id;
        scholarshipData.institution_id = this.state.institution_id;
        try {
            scholarshipData.scholarship_id = this.props.scholarshipId;
            scholarshipData.shift = this.state.shift;
            scholarshipData.type = this.state.type;
            await Api.post('/scholarship/new_value', scholarshipData);
            this.props.onSuccess("Cadastro atualizado");
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.userMessage) return this.props.onError(error.response.data.userMessage);
                return this.props.onError(error.response.data.message)
            }
            this.props.onError(error.message);
        }
    }

    startModal = async () => {
        if (this.props.scholarshipId) {
            try {
                let response = await Api.get('/scholarship/' + this.props.scholarshipId);
                let scholarship = response.data.scholarship;
                this.setState({
                    shift: scholarship.shift,
                    type: scholarship.type,
                    full_price: scholarship.full_price,
                    full_price_float: scholarship.full_price,
                    discount: scholarship.discount,
                    quantity_offered: scholarship.quantity_offered,
                    renovation_days: scholarship.renovation_days,
                    register_period_start: new Date(scholarship.register_period_start),
                    register_period_end: scholarship.register_period_end ? new Date(scholarship.register_period_end) : null,
                    course_description: scholarship.course_description,
                    course_id: scholarship.course_id,
                    institution_id: scholarship.institution_id,
                });

                this.state({teste: scholarship});
            } catch (error) {
                console.log(error)
            }
        }
    }

    handleClose = () => {
        this.clearForm();
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    clearForm = () => {
        this.setState({
            shift: "",
            type: "",
            full_price: null,
            full_price_float: null,
            discount: "",
            quantity_offered: "",
            renovation_days: "",
            register_period_start: new Date(),
            register_period_end: null,
            course_description: "Descrição do curso",
            course_id: "",
            institution_id: "",
        })
    }

    handleFullPriceChange = (event, maskedvalue, floatvalue) => {
        this.setState({ full_price: maskedvalue, full_price_float: floatvalue });
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Atualizar valor da bolsa</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-3 pt-2 pb-0">
                    <form id="scholarship-form" onSubmit={e => { this.handleSubmit(e) }}>
                    <div className="row no-gutters mt-3">
                            <div className="col-12">
                                <label>Datas do período de matrícula</label>
                            </div>
                            <div className="col-sm-6 pr-sm-2">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.register_period_start}
                                    className="form-control d-flex"
                                    placeholderText="Inicio das matrículas"
                                    onChange={value => { this.setState({ register_period_start: value }) }}
                                    required
                                />
                            </div>
                            <div className="col-sm-6 pl-sm-2">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.register_period_end}
                                    className="form-control d-flex"
                                    placeholderText="Fim das matrículas"
                                    onChange={value => { this.setState({ register_period_end: value }) }}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col-6">
                                <label>Valor</label>
                            </div>
                            <div className="col-6 pl-2">
                                <label>Desconto</label>
                            </div>
                            <div className="col-sm-6 pr-sm-2">
                                <CurrencyInput
                                    value={this.state.full_price}
                                    onChangeEvent={this.handleFullPriceChange}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    className="form-control"
                                    placeholder="Valor integral"
                                    required
                                />
                            </div>
                            <div className="col-sm-6 pl-sm-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="discount"
                                    value={this.state.discount}
                                    onChange={e => { this.setState({ discount: e.target.value }) }}
                                    placeholder="Ex: 50%"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col-6">
                                <label>Dias para renovação</label>
                            </div>
                            <div className="col-6 pl-2">
                                <label>Bolsas ofertadas</label>
                            </div>
                            <div className="col-6 pr-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="renovation_days"
                                    value={this.state.renovation_days}
                                    onChange={e => { this.setState({ renovation_days: e.target.value }) }}
                                    placeholder="Ex: 180"
                                    required
                                />
                            </div>
                            <div className="col-6 pl-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="quantity_offered"
                                    value={this.state.quantity_offered}
                                    onChange={e => { this.setState({ quantity_offered: e.target.value }) }}
                                    placeholder="Ex: 5"
                                    required
                                />
                            </div>
                        </div>
                        <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3">
                            <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                            <button type="submit" className="btn btn-primary">Salvar</button>
                        </div>
                    </form>
                </div>
            </ReactModal>
        )
    }
}