import React, { Component } from 'react'
import Api from '../../Services/Cloud';
import Loading from '../../components/Loading';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

export default class Impact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            institutions: [],
            loading: true,
            selectedInstitution: "all",
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (update) => {
        let institutionsRequest = await Api.get('/institution');
        let institutions = institutionsRequest.data.institutions;
        this.setState({ institutions, loading: false });
    }

    handleSubmit = (e) => {
        if (this.state.selectedInstitution === 'all') {
            e.preventDefault();
            alert('Selecione uma instituição.');
        };
    }

    render() {
        if (this.state.loading) return <Loading />;
        if (this.state.submit) return <Redirect to={{ pathname: '/impact_report', search: `?institution=${this.state.selectedInstitution}` }} />
        return (
            <div className="mt-2 w-100">
                <form className="row p-1" onSubmit={this.handleSubmit}>
                    <div className="form-group col-md-3">
                        <select
                            className="form-control"
                            name="institution"
                            value={this.state.selectedInstitution}
                            onChange={e => { this.setState({ selectedInstitution: e.target.value }) }}
                            required
                        >
                            <option disabled value="">Selecionar instituição</option>
                            <option value="all">TODAS</option>
                            {this.state.institutions.map(institution => {
                                return <option value={institution.id} key={institution.id}>{institution.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="form-group col-md-2">
                        <Link
                            to={{
                                pathname: '/impact_report',
                                search: `?institution=${this.state.selectedInstitution}&course=${this.state.selectedCourse}&start_period=${moment(this.state.start_period).format('YYYY-MM-DD')}&end_period=${moment(this.state.end_period).format('YYYY-MM-DD')}`,
                            }}
                            target="_blank"
                            onClick={this.handleSubmit}
                            className="btn btn-primary btn-block">
                            <span>BUSCAR</span>
                        </Link>
                    </div>
                </form>
            </div>
        )
    }
}
