import React, { Component } from 'react'
import queryString from 'query-string';
import Api from '../../Services/Cloud';
import { parseMoney } from '../../Util/Functions';

import './index.css';
import moment from 'moment';

export default class ImpactReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {},
            institution: null,
            loading: true,
            errored: false
        }
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        this.setState({ params }, () => { this.getData(); });
    }

    getData = async () => {
        try {
            let institutionsRequest = await Api.get('/institution/id/' + this.state.params.institution);
            let scholarshipsRequest = await Api.get('/report/impact', { params: { institution: this.state.params.institution } })
            this.setState({ loading: false, institution: institutionsRequest.data.institution, scholarships: scholarshipsRequest.data.scholarships });
        } catch (error) {
            alert('Erro ao consultar dados.');
        }
    }

    render() {
        if (this.state.errored) return <div>ERRO</div>
        if (this.state.loading) return <div>Carregando</div>
        return (
            <div className="impact_container">
                <table className="border">
                    <tbody>
                        <tr>
                            <td width="10%"><img src={require('../../assets/images/brasil-logo.png')} alt="" /></td>
                            <td width="90%" className="text-center">
                                <span>FORMULÁRIO PARA LIBERAÇÃO DE VAGAS</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className="row no-gutters w-100 d-flex justify-content-center"> <span>1. Dados da instituição de ensino</span> </div>

                <table border="1" className="institution-data">
                    <tbody>
                        <tr>
                            <td width="10%">Razão social:</td>
                            <td>{this.state.institution.name}</td>
                            <td></td>
                            <td width="15%">CNPJ:</td>
                            <td className="text-center">{this.state.institution.cnpj}</td>
                        </tr>
                        <tr>
                            <td width="10%">Endereço:</td>
                            <td>{this.state.institution.street}</td>
                            <td width="15%"></td>
                            <td>Data Contrato:</td>
                            <td className="text-center"> {moment(this.state.institution.createdAt).format('DD-MM-YYYY')} </td>

                        </tr>
                        <tr>
                            <td width="10%">Bairro:</td>
                            <td>{this.state.institution.district}</td>
                            <td></td>
                            <td>Estado: </td>
                            <td className="text-center">{this.state.institution.state}</td>

                        </tr>
                        <tr>
                            <td width="10%">Cidade:</td>
                            <td>{this.state.institution.city}</td>
                            <td></td>
                            <td>CEP:</td>
                            <td className="text-center">{this.state.institution.postal_code}</td>

                        </tr>
                        <tr>
                            <td width="10%">Fantasia:</td>
                            <td>{this.state.institution.name}</td>
                            <td colSpan="3"></td>
                        </tr>
                        <tr>
                            <td width="10%">Telefone:</td>
                            <td>{this.state.institution.phone}</td>
                            <td colSpan="3"></td>
                        </tr>
                    </tbody>
                </table>

                <table border="1" className="mt-2">
                    <tbody>
                        <tr>
                            <td width="10%">Site: </td>
                            <td width="30%"></td>
                            <td width="30%"></td>
                            <td width="15%">Alunos matriculados:</td>
                            <td width="5%" className="text-center">{this.state.institution.students_count}</td>
                            <td width="10%"></td>
                        </tr>
                    </tbody>
                </table>

                <div className="row no-gutters w-100 d-flex justify-content-center mt-4"> <span>2. Diretor (Quem assina em nome da instituição)</span> </div>

                <table border="1">
                    <tbody>
                        <tr>
                            <td width="10%">Nome: </td>
                            <td colSpan="2" width="30%">{this.state.institution.owner_name}</td>
                            <td width="5%">Cargo:</td>
                            <td colSpan="2" width="20%">Diretor(a)</td>
                        </tr>
                        <tr>
                            <td width="10%">E-mail: </td>
                            <td></td>
                            <td></td>
                            <td width="5%">Telefone:</td>
                            <td>{this.state.institution.owner_phone}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>


                <table border="1" className="data-table mt-1">
                    <thead>
                        <tr>
                            <th>Série</th>
                            <th>Turno</th>
                            <th>Impacto Financeiro</th>
                            <th>Vagas Propostas</th>
                            <th>Vagas Aprovadas</th>
                            <th>Valor da mensalidade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.scholarships.map(scholarship => (
                            <tr key={scholarship.id}>
                                <td>{scholarship.course.name}</td>
                                <td className="text-center">{scholarship.shift.toUpperCase()}</td>
                                <td className="text-center">{parseMoney(scholarship.full_price * scholarship.payments_count)}</td>
                                <td className="text-center">{scholarship.quantity_offered}</td>
                                <td className="text-center">{scholarship.payments_count}</td>
                                <td className="text-center">{parseMoney(scholarship.full_price)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>




            </div>
        )
    }
}
