import React, { Component } from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';

import './index.css';

import Api from '../../../Services/Cloud';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import CurrencyInput from 'react-currency-input';
import moment from 'moment';
import axios from 'axios';
import { CPFValidate } from '../../../Util/Functions';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 2
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%'
    }
};

ReactModal.setAppElement('#root');

export default class Modal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onError: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            newScholarshipId: '',
            userId: null,
            minor_birthdate: null,
            phone: "",
            cpf: "",
            rg: "",
            rg_emissor: "",
            password: "",
            birthdate: "",
            familyIncome: null,
            familyIncomeDouble: 0,
            address: {
                street: "",
                city: "",
                state: "",
                number: "",
                postalCode: "",
                district: "",
            },
            addressLoading: false,
            postalCodeError: false,
            loginUsername: "",
            loginPassword: "",
            selfResponsible: false,
            loading: false
        }
    }

    componentDidMount() {
        this.startModal();
    }

    handleCepChange = async (value) => {
        this.setState({ addressLoading: true, postalCodeError: false });
        try {
            let response = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
            this.setState({ addressLoading: false });
            if (response.data.erro === true) {
                this.setState({
                    postalCodeError: true,
                    address: {
                        street: "",
                        district: "",
                        city: "",
                        state: ""
                    }
                });
            } else {
                this.setState({
                    address: {
                        street: response.data.logradouro,
                        district: response.data.bairro,
                        city: response.data.localidade,
                        state: response.data.uf
                    },
                });
            }
        } catch (error) {
            console.log(error)
            this.setState({ postalCodeError: true, addressLoading: false })
            console.log(error.response);
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ requesting: true });
        let data = {
            orderId: this.props.orderId,
            newScholarshipId: this.state.newScholarshipId
        }
        this.setState({ loading: true });
        var formData = new FormData(document.getElementById('register-form'));
        var userData = {};
        formData.forEach((value, key) => { userData[key] = value });
        userData.user_id = this.state.userId;
        userData.family_income = this.state.familyIncomeFloat;
        userData.has_dependent = userData.minor_name !== "";
        userData.minor_birthdate = userData.minor_birthdate !== "" ? moment(userData.minor_birthdate, 'DD/MM/YYYY').format('YYYY/MM/DD') : null;
        userData.birthdate = moment(userData.birthdate, 'DD/MM/YYYY').format('YYYY/MM/DD');
        
        if (!CPFValidate(userData.cpf.replace(/\D+/g, ''))) {
            this.setState({ loading: false });
            return toast.error('CPF inválido.', { position: toast.POSITION.BOTTOM_RIGHT });
        }
        try {
            if (this.state.newScholarshipId !== '') await Api.put('/order/change', data);
            await Api.put('/user', userData);

            this.setState({ requesting: false });
            toast.success('Dados atualizados');
            this.clear();
            this.props.onSuccess();
        } catch (error) {
            this.setState({ requesting: false });
            if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
            if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
            return toast.error(error.message);
        }
    }

    clear = () => {
        this.setState({
            newScholarshipId: '',
            requesting: false
        })
    }

    startModal = async () => {
        if (this.props.orderId) {
            try {
                console.log(this.props.orderId)
                let response = await Api.get('/order/id/' + this.props.orderId);
                let order = response.data.order;
                this.setState({
                    userId: order.UserId,
                });
                const user = await Api.get('/user/id/' + order.UserId);
                this.setState({
                    phone: user.data.user.phone,
                    minor_birthdate: user.data.user.minor_birthdate !== "" ? moment(user.data.user.minor_birthdate).toDate() : null,
                    cpf: user.data.user.cpf,
                    rg: user.data.user.rg,
                    birthdate: user.data.user.birthdate !== "" ? moment(user.data.user.birthdate).toDate() : null,
                    familyIncome: user.data.user.family_income,
                    friend_phone: user.data.user.friend_phone,
                    email: user.data.user.email,
                    minor_name: user.data.user.minor_name,
                    whatsapp_phone: user.data.user.whatsapp_phone,
                    name: user.data.user.name,
                    rg_emissor: user.data.user.rg_emissor,
                });

            } catch (error) {
                console.log(error)
            }
        }
    }

    handleClose = () => {
        this.clear();
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Mudança de usuário</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-3 pt-2 pb-0 d-f">

                <form className="row p-md-3" onSubmit={this.handleSubmit} id="register-form">
                            <div className="col-12">
                                <div className='mb-3'>
                                <div><h4>Nova bolsa:</h4></div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={this.state.newScholarshipId}
                                        onChange={e => { this.setState({ newScholarshipId: e.target.value }) }}
                                        placeholder="Nova bolsa"
                                    />
                                </div>
                                <div>
                                    <div><h4>Dados do aluno</h4></div>
                                    <div className="form-group">
                                        <input
                                            value={this.state.minor_name}
                                            onChange={e => { this.setState({ minor_name: e.target.value }) }}
                                            className="form-control"
                                            name="minor_name"
                                            placeholder="Nome completo do aluno"
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <MaskedInput
                                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                            className="form-control"
                                            name="minor_birthdate"
                                            placeholder="Data de nascimento do aluno"
                                            defaultValue={this.state.minor_birthdate ? moment(this.state.minor_birthdate).format('DD/MM/YYYY') : ""}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12"><h4>Dados do responsável</h4></div>
                            <div className="col-12 form-group">
                                <input
                                    className="form-control"
                                    name="name"
                                    placeholder="Nome completo"
                                    defaultValue={this.state.name ? this.state.name : ""}
                                    required 
                                />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="birthdate"
                                    placeholder="Data de nascimento do responsável"
                                    defaultValue={this.state.birthdate ? moment(this.state.birthdate).format('DD/MM/YYYY') : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="phone"
                                    placeholder="Telefone"
                                    defaultValue={this.state.phone ? this.state.phone : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="whatsapp_phone"
                                    placeholder="Telefone (Whatsapp)"
                                    defaultValue={this.state.whatsapp_phone ? this.state.whatsapp_phone : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    name="friend_phone"
                                    placeholder="Telefone para recado (amigo ou parente)"
                                    defaultValue={this.state.friend_phone ? this.state.friend_phone : ""}
                                    required
                                />
                            </div>
                            <div className="col-6 col-md-7 form-group">
                                <input 
                                className="form-control" 
                                name="rg" 
                                defaultValue={this.state.rg ? this.state.rg : ""}
                                placeholder="RG" 
                                required />
                            </div>
                            <div className="col-6 col-md-5 form-group">
                                <input 
                                    className="form-control" 
                                    name="rg_emissor" 
                                    defaultValue={this.state.rg_emissor ? this.state.rg_emissor : ""}
                                    placeholder="órg. Emissor" 
                                    required 
                                />
                            </div>
                            <div className="col-12 form-group">
                                <CurrencyInput
                                    value={this.state.familyIncome}
                                    onChangeEvent={this.handleFamilyIncomeChange}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    className="form-control"
                                    placeholder="Renda familar mensal"
                                    required
                                />
                            </div>
                            <div className="col-11 form-group">
                                <input className="form-control" name="postal_code" onBlur={e => { this.handleCepChange(e.target.value) }} placeholder="CEP" required />
                                {this.state.postalCodeError
                                    ?
                                    (
                                        <div className="cep-error-message text-danger">
                                            Erro ao buscar endereço
                                    </div>
                                    )
                                    :
                                    null
                                }
                            </div>
                            {this.state.addressLoading
                                ?
                                (
                                    <div className="col-1 form-group d-flex align-items-center justify-content-center">
                                        <i className="fas fa-circle-notch fa-spin"></i>
                                    </div>
                                )
                                :
                                null
                            }
                            <div className="col-md-7 form-group">
                                <input
                                    value={this.state.address.street}
                                    onChange={e => { this.setState({ address: { ...this.state.address, street: e.target.value } }) }}
                                    className="form-control"
                                    name="street"
                                    placeholder="Rua"
                                    required
                                />
                            </div>
                            <div className="col-md-5 form-group">
                                <input
                                    value={this.state.address.number}
                                    onChange={e => { this.setState({ address: { ...this.state.address, number: e.target.value } }) }}
                                    className="form-control"
                                    name="number"
                                    placeholder="Número"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    className="form-control"
                                    name="complement"
                                    placeholder="Complemento"
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    value={this.state.address.city}
                                    onChange={e => { this.setState({ address: { ...this.state.address, city: e.target.value } }) }}
                                    className="form-control"
                                    name="city"
                                    placeholder="Cidade"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <input
                                    value={this.state.address.district}
                                    onChange={e => { this.setState({ address: { ...this.state.address, district: e.target.value } }) }}
                                    className="form-control"
                                    name="district"
                                    placeholder="Bairro"
                                    required
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <select
                                    className="form-control"
                                    placeholder="Estado"
                                    defaultValue={this.state.address.state}
                                    value={this.state.address.state}
                                    onChange={e => { this.setState({ address: { ...this.state.address, state: e.target.value } }) }}
                                    name="state"
                                    required
                                >
                                    <option disabled value="">Selecione o estado</option>
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">Mato Grosso do Sul</option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">Rio Grande do Norte</option>
                                    <option value="RS">Rio Grande do Sul</option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                </select>
                            </div>
                            <div className="col-12"><h4>Dados de acesso</h4></div>
                            <div className="col-12 form-group">
                                <input 
                                    className="form-control" 
                                    type="email" 
                                    name="email"
                                    placeholder="Email" 
                                    defaultValue={this.state.email ? this.state.email : ""}
                                    required 
                                />
                            </div>
                            <div className="col-12 form-group">
                                <MaskedInput
                                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
                                    className="form-control"
                                    name="cpf"
                                    placeholder="CPF"
                                    defaultValue={this.state.cpf ? this.state.cpf : ""}
                                    required
                                />
                            </div>
                            <div className="col-12 form-group">
                                <input type="password" className="form-control" name="password" placeholder="Senha" />
                            </div>
                            <div className="col-md-5 mb-3">
                                <button type="submit" className=" btn btn-secondary btn-block text-white">Salvar</button>
                            </div>
                        </form>
                </div>
            </ReactModal>
        )
    }
}