import React, { Component } from 'react';

import Api from '../../Services/Cloud';
import DataTable from '../../components/DataTable';
import Modal from './Modal';
import Loading from '../../components/Loading';

import $ from 'jquery';
import { toast } from 'react-toastify';

import '../../Libs/fontawesome/css/all.css';

const columns = [
    { title: "Nome", data: "name" },
    { title: "Tipo" },
    { title: "Email", data: "email" },
    { title: "Ativo" },
    { title: "Ações" },
];

const columnDefs = [
    {
        targets: [1],
        render: function (data, type, row) {
            switch (row.type) {
                case 'admin':
                    return 'Administrador'
                case 'manager':
                    return 'Parceiro'
                default:
                    return 'Erro'
            }
        }
    },
    {
        targets: [3],
        render: function (data, type, row) {
            return row.active ? 'Sim' : 'Não'
        }
    },
    {
        targets: -1,
        data: null,
        defaultContent: '<a  class=" action-button" data-toggle="modal"><i class="fas fa-edit action-icon edit-user"></i></span></a>'
            + '<a  class=" action-button"><i class="fas fa-check action-icon toggle-user"></i></span></a>'
            + '<a  class=" action-button"><i class="far fa-trash-alt action-icon delete-user"></i></span></a>'
    }
];


export default class Users extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            loading: true,
            modalCreateOpen: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async (update) => {
        let response = await Api.get('/user/system_users/list');
        this.setState({ users: response.data.users, loading: false });
        if (update === true) {
            this.table.reloadTableData(response.data.users);
        }
    }

    handleSuccess = (message) => {
        this.toggleModal();
        this.getData(true);
        toast.success(message);
    }

    handleError = (message) => {
        toast.error(message);
    }

    handleTableClick = (e, dataTable) => {
        let target = $(e.target);
        var current_row = target.parents('tr');
        if (current_row.hasClass('child')) {
            current_row = current_row.prev();
        }
        var data = dataTable.row(current_row).data();
        if (e.target.className.includes('toggle-user')) {
            let stringMessage = `Deseja habilitar o usuário ${data.name}?`;
            if (data.active === true) stringMessage = `Deseja desabilitar o usuário ${data.name}?`;
            var confirm = window.confirm(stringMessage);
            if (confirm) this.toggleUser(data.id);
        }
        if (e.target.className.includes('delete-user')) {
            if (window.confirm(`Deseja excluir o usuário ${data.name}?`)) this.deleteUser(data.id);
        }
        else if (e.target.className.includes('edit-user')) {
            this.openUserEditModal(data.id);
        }
    }

    openUserEditModal = (userId) => {
        this.setState({ userId }, this.toggleModal)
    }

    toggleUser = async (userId) => {
        try {
            await Api.put('/user/toggle', { user_id: userId });
            toast.success('Usuário atualizado.')
            this.getData(true);
        } catch (error) {
            if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
            if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
            return toast.error(error.message);
        }
    }

    deleteUser = async (userId) => {
        try {
            await Api.delete('/user', { data: { user_id: userId } });
            toast.success('Usuário excluído.')
            this.getData(true);
        } catch (error) {
            if (error.response.data && error.response.data.userMessage) return toast.error(error.response.data.userMessage);
            if (error.response.data && error.response.data.message) return toast.error(error.response.data.message);
            return toast.error(error.message);
        }
    }

    toggleModal = () => {
        if (this.state.modalCreateOpen) {
            this.setState({ userId: null });
        }
        this.setState({ modalCreateOpen: !this.state.modalCreateOpen })
    }

    render() {
        if (this.state.loading) return <Loading />;
        return (
            <div className="mt-2 w-100">
                <button className="btn btn-primary mb-4" onClick={this.toggleModal}>CADASTRAR</button>
                <DataTable
                    data={this.state.users}
                    columns={columns}
                    options={{
                        buttons: [],
                        responsive: true,
                        columnDefs: columnDefs,
                        destroy: true
                    }}
                    handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
                    ref={ref => this.table = ref}
                />
                <Modal
                    isOpen={this.state.modalCreateOpen}
                    shouldCloseOnEsc={true}
                    userId={this.state.userId ? this.state.userId : null}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.toggleModal}
                    onSuccess={message => { this.handleSuccess(message) }}
                    onError={message => { this.handleError(message) }}
                />
            </div>
        )
    }
}

