import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Route,
    Redirect,
} from 'react-router-dom';

import App from './App';
import ImpactReport from './views/ImpactReport';
import Login from './views/Login';

const checkAuth = () => {
    return localStorage.getItem('logged');
};

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                checkAuth() ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
}

// Verifica se a página está sendo carregada via HTTP e redireciona para HTTPS
if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
    window.location.href = `https://${window.location.host}${window.location.pathname}`;
} else {
    const Main = () => (
        <Router>
            <PrivateRoute path="/impact_report" component={ImpactReport} />
            <PrivateRoute path="/app" component={App} />
            <PrivateRoute exact path="/" component={App} />
            <Route path="/login" component={Login} />
        </Router>
    );

    ReactDOM.render(<Main />, document.getElementById('root'));
}
