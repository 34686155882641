import React, { Component } from 'react';
import ReactModal from 'react-modal';

import moment from 'moment';
import Api from '../../Services/Cloud';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 2
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '40rem'
    }
};

ReactModal.setAppElement('#root');

export default class Notifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
           notifications: [],
        }
    }

    startModal = async () => {
        try {
            let response = await Api.get('/notifications');
            let notifications = response.data;
            console.log(notifications)
            this.setState({ notifications });

        } catch (error) {
            console.log(error)
        }
    }

    handleClose = () => {
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Notificações</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-4 pt-2 pb-0">
                    {this.state.notifications.map((notification, index) => (
                        <div key={index} className="d-flex justify-content-between align-items-center border-bottom py-2">
                            <div>
                                <h6>{notification.title}</h6>
                                <p className="fa-sm">{notification.message}</p>
                            </div>
                            <div className="ml-4">
                                <p className="text-muted fa-xs text-center">
                                    {moment(notification.createdAt).format('DD/MM/YYYY HH:mm')}
                                </p>
                            </div>
                        </div>
                    ))} 
                </div>
            </ReactModal>
        )
    }
}