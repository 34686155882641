import axios from "axios";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

headers.Authorization = "bearer " + localStorage.getItem("userToken");

// Verifica o ambiente de desenvolvimento
const isDevelopment = process.env.NODE_ENV === 'production';

// Define a URL base com base no ambiente
const baseURL = isDevelopment
  ? 'https://api.brasilmaisbolsas.com.br/v1'
  : 'http://localhost:3001/v1';

console.log(baseURL);

// Cria a instância da API com a URL apropriada
const Api = axios.create({
  baseURL: baseURL,
  headers: headers,
});

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === "token-expired"
    ) {
      localStorage.clear();
      return window.location.replace("/login?expired=true");
    }
    return Promise.reject(error);
  }
);

export default Api;
