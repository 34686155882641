import React, { Component } from 'react'

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import portuguese from '../../Util/DtLanguages';

import $ from 'jquery';
import 'datatables.net-bs4';
import 'pdfmake/build/pdfmake.js';
import 'pdfmake/build/vfs_fonts.js';
import 'datatables.net-buttons-bs4';
import 'datatables.net-scroller-bs4';
import 'datatables.net-responsive-bs4';
import { removeAcento } from '../../Util/Functions';

require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.print.js');
require('datatables.net-rowgroup-bs4');

pdfMake.vfs = pdfFonts.pdfMake.vfs;

require('./index.css');

const columnDefs = [
    {
        "defaultContent": "-",
        "targets": "_all"
    }
];

export default class DataTable extends Component {

    componentDidMount() {
        this.$table = $(this.table);
        $(this.table).append(
            $('<tfoot/>')
        );
        var table = this.$table.DataTable({
            buttons: this.props.buttons,
            data: this.props.data,
            dom:
                "<'row'<'col-sm-12 mb-2 col-md-4'B>>" +
                "<'row'<'col-sm-8 d-flex align-items-center'l><'col-sm-12 col-md-4'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
            responsive: true,
            columns: this.props.columns,
            ordering: false,
            language: portuguese,
            ...this.props.options,
            columnDefs: this.props.options && this.props.options.columnDefs // Adiciona o columnDefs se ele existir em options
                ? [...this.props.options.columnDefs, ...columnDefs] // Concatena os columnDefs existentes com os novos
                : columnDefs, // Caso não exista columnDefs em options, usa o columnDefs padrão
        });
        this.dtable = table;
        this.$table.off('click', 'a');
        this.$table.on('click', 'a', (e) => {
            this.props.handleActionClick(e, table);
        });
        $('.data-table-wrapper').find('input:first').off('keyup');
        $('.data-table-wrapper').find('input:first').on('keyup', (e) => {
            $('.data-table-wrapper').find('table').DataTable().search(removeAcento(e.target.value)).draw();
        });

    }

    reloadTableData = (data) => {
        const table = $('.data-table-wrapper')
            .find('table')
            .DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    getTable = () => {
        return this.dtable;
    }

    render() {
        return (
            <div className="data-table-wrapper">
                <table
                    className="table table-striped table-bordered"
                    width="100%"
                    id="institutionsTable"
                    ref={table => (this.table = table)}
                >
                    {this.props.report ?
                        <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </tfoot> : null}
                </table>
            </div>
        )
    }
}
