import React, { Component } from 'react';


import Loading from '../../components/Loading';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { Collapse } from 'react-collapse';
import '../../Libs/fontawesome/css/all.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as rdrLocales from 'react-date-range/src/locale';
import ReactApexChart from 'react-apexcharts'

import './index.css';

export default class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showFilters: false,
            masterSelector: 'students',
            startDate: null,
            endDate: null,

            series: [{
                name: 'Net Profit',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            }, {
                name: 'Revenue',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }, {
                name: 'Free Cash Flow',
                data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: '$ (thousands)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$ " + val + " thousands"
                        }
                    }
                }
            },
        }
    }

    componentDidMount() {
    }

    toggleFilters = () => { this.setState({ showFilters: !this.state.showFilters }) };


    handleDateRangeSelect = (ranges) => {
        this.setState({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate }, () => {

        })
    }

    handleMasterSelect = (e) => {
        this.setState()
    }

    render() {
        if (this.state.loading) return <Loading />;
        const selectionRange = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            key: 'selection',
        }
        return (
            <div className="mt-2 w-100 dashboard">
                <div className="row">
                    <div className="col-lg-auto mt-2 mt-lg-0 d-flex align-items-center">
                        <span>Mostrando {this.state.startDate ? 'de ' + moment(this.state.startDate).format('DD/MM/YYYY') : 'este mês'} {this.state.endDate ? 'até ' + moment(this.state.end).format('DD/MM/YYYY') : null}</span>
                        <button className="btn btn-link" onClick={this.toggleFilters}>Mudar período</button>
                    </div>
                    <div className="col-lg-auto mt-2 mt-lg-0 d-flex align-items-center">
                        <span htmlFor="master-selector">Quero ver:</span>
                        <select
                            className="form-control ml-2"
                            value={this.state.masterSelector}
                            id="master-selector"
                            onChange={this.handleMasterSelect}
                        >
                            <option value="students">Matriculas</option>
                            <option value="sellers">Vendedores</option>
                            <option value="Bolsas">Bolsas</option>
                        </select>
                    </div>
                    {
                        <div className="col-lg-auto mt-2 mt-lg-0 d-flex align-items-center">
                            <span>Status:</span>
                            <select className="form-control ml-2">
                                <option value="all">Todas</option>
                                <option value="PAID">Pagas</option>
                                <option value="payment-not-started">Não inicou pagamento</option>
                                <option value="payment-started">Iniciou pagamento</option>
                            </select>
                        </div>
                    }
                    <div className="col-lg-auto mt-2 mt-lg-0 d-flex align-items-center">
                        <button className="btn btn-primary"><span>PESQUISAR</span></button>
                        <button className="btn btn-link" onClick={this.toggleFilters}>Resetar filtros</button>
                    </div>
                    <div className="col-lg-8 mt-4">
                        <Collapse isOpened={this.state.showFilters}>
                            <DateRangePicker
                                locale={rdrLocales.ptBR}
                                ranges={[selectionRange]}
                                onChange={this.handleDateRangeSelect}
                            />
                        </Collapse>
                    </div>
                    <div className="col-12">
                        <ReactApexChart locale={rdrLocales.ptBR} options={this.state.options} series={this.state.series} type="bar" height={350} />
                    </div>
                </div>
            </div>
        )
    }
}

