import React, { Component } from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';

import 'react-quill/dist/quill.snow.css';
import './index.css';

import Api from '../../../Services/Cloud';
import Loader from 'react-loading-components';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 10
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%'
    }
};

ReactModal.setAppElement('#root');

export default class RefoundModal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onError: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            percent: 0,
            loading: false,
            student: {}
        }
    }

    componentDidMount() {
        this.clearForm();
        this.startModal();
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let percent = this.state.percent;

        if (!percent) return this.props.onError("Informe o percentual do valor a ser estornado");
        if (percent < 20 || percent > 100) return this.props.onError("O percentual deve ser entre 0 e 100");

        try {
            console.log('percent', percent);
            this.setState({ loading: true });
            await Api.post('/payment/refound', { user_id: this.props.studentId, percent });
            this.setState({ percent: "" });
            this.props.onSuccess("Solicitação de estorno realizada com sucesso");
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.userMessage) return this.props.onError(error.response.data.userMessage);
                return this.props.onError(error.response.data.message)
            }
            this.props.onError(error.message);
        } finally {
            this.setState({ loading: false });
            this.handleClose();
        }
    }

    handleCancel = async () => {
        try {
            this.setState({ loading: true });
            await Api.post('/payment/cancel', { user_id: this.props.studentId });
            this.props.onSuccess("Matricula cancelada com sucesso");
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.userMessage) return this.props.onError(error.response.data.userMessage);
                return this.props.onError(error.response.data.message)
            }
            this.props.onError(error.message);
        } finally {
            this.setState({ loading: false });
            this.handleClose();
        }
    }

    startModal = async () => {
        console.log(this.props.studentId);
        this.setState({ percent: '' })
    }

    handleClose = () => {
        this.clearForm();
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    clearForm = () => {
        this.setState({
            percent: "",
        })
    }

    handlePercentChange = (e) => {
        let value = e.target.value || '';
        if (!isNaN(value) && value.length <= 3) {
            this.setState({ percent: value });
        }
    }

    render() {
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Estornar pagamento</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-3 pt-2 pb-0">
                    <form id="course-form" onSubmit={e => { this.handleSubmit(e) }}>
                        <div className="form-group">
                            <label>Qual percentual do valor será estornado ?</label>
                            
                            <div className="input-group" style={{ border: '1px solid #ced4da', borderRadius: '4px', overflow: 'hidden', backgroundColor: '#fff' }}>
                                <input
                                    value={this.state.percent}
                                    onChange={this.handlePercentChange}
                                    className="form-control border-0"
                                    type="number"
                                    style={{ flex: 1, boxShadow: 'none' }}
                                />
                                <span
                                    className="input-group-text bg-white border-0"
                                    style={{ borderLeft: '1px solid #ced4da' }}
                                >%</span>
                            </div>
                        </div>
                        <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3">
                            {this.state.loading ? <Loader type='tail_spin' width={40} height={40} fill='#14496b' /> : (
                                <>
                                    <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                                    <button type="button" className="btn btn-primary mr-3" title='Cancelar matrícula' onClick={this.handleCancel}>Cancelar Matrícula</button>
                                    <button type="submit" className="btn btn-primary">Salvar</button>
                                </>
                            )}
                            {/* <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                            <button type="button" className="btn btn-primary mr-3" title='Cancelar matrícula' onClick={this.handleCancel}>Cancelar Matrícula</button>
                            <button type="submit" className="btn btn-primary">Salvar</button> */}
                        </div>
                    </form>
                </div>
            </ReactModal>
        )
    }
}