import React, { Component } from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import DatePicker from "react-datepicker";
import CurrencyInput from 'react-currency-input';
import $ from 'jquery';

import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'

import "react-datepicker/dist/react-datepicker.css";
import 'react-quill/dist/quill.snow.css';
import './index.css';

import Api from '../../../Services/Cloud';
import { toast } from 'react-toastify';
import moment from 'moment';

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.50)',
        zIndex: 2
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: 0,
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%'
    }
};

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
];

ReactModal.setAppElement('#root');

export default class Modal extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onError: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            scholarships: [],
            courses: [],
            institutions: [],
            shift: "",
            type: "",
            full_price: null,
            full_price_float: null,
            discount: "",
            quantity_offered: "",
            renovation_days: "",
            register_period_start: new Date(),
            register_period_end: null,
            course_description: "Descrição do curso",
            course_period: [{
                value: ""
            }],
            course_id: "disabled",
            institution_id: "disabled",
        }
    }

    componentDidMount() {
        this.getCourses();
        this.getInstitutions();
    }

    startSelect2 = () => {
        var ctx = this;
        $.fn.select2.defaults.set("language", {
            noResults: function () { return "Sem resultados" },
            noMatches: function () { return "Nenhum resultado encontrado"; },
            inputTooShort: function (input, min) { return 'Digite no mínimo 2 caracteres.' },
            loadMore: function (pageNumber) { return "Buscando..."; },
            searching: function () { return "Buscando..."; }
        });
        $('.institution-select').select2({
            placeholder: "Instituição",
            theme: "bootstrap4",
        });
        $('.course-select').select2({
            placeholder: "Curso",
            theme: "bootstrap4",
        });
        $('.institution-select').on('select2:select', async function (e) {
            ctx.setState({ institution_id: e.target.value });
        });
        $('.course-select').on('select2:select', async function (e) {
            ctx.setState({ course_id: e.target.value });
        });
    }

    getCourses = async () => {
        try {
            let response = await Api.get('course');
            console.log(response)
            let courses = response.data.courses;
            this.setState({ courses: courses })
        } catch (error) {
            toast.error("Erro ao buscar cursos");
            console.log(error)
        }
    }

    getInstitutions = async () => {
        try {
            let response = await Api.get('institution');
            let institutions = response.data.institutions;
            console.log(institutions)
            this.setState({ institutions: institutions })
        } catch (error) {
            toast.error("Erro ao buscar instituições");
            console.log(error.response)
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData(document.getElementById('scholarship-form'));
        var scholarshipData = {};
        formData.forEach((value, key) => { scholarshipData[key] = value });
        scholarshipData.course_period = this.state.course_period;
        scholarshipData.full_price = this.state.full_price_float;
        scholarshipData.register_period_start = moment(this.state.register_period_start).format('YYYY/MM/DD');
        if (this.state.register_period_end) scholarshipData.register_period_end = moment(this.state.register_period_end).format('YYYY/MM/DD');
        scholarshipData.course_description = this.state.course_description;
        scholarshipData.course_id = this.state.course_id;
        scholarshipData.institution_id = this.state.institution_id;
        try {
            if (this.props.scholarshipId) {
                scholarshipData.scholarship_id = this.props.scholarshipId;
                await Api.put('/scholarship', scholarshipData);
                this.props.onSuccess("Cadastro atualizado");
            } else {
                await Api.post('/scholarship', scholarshipData);
                this.props.onSuccess("Cadastro realizado");
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.data.userMessage) return this.props.onError(error.response.data.userMessage);
                return this.props.onError(error.response.data.message)
            }
            this.props.onError(error.message);
        }
    }

    startModal = async () => {
        if (this.props.scholarshipId) {
            try {
                let response = await Api.get('/scholarship/' + this.props.scholarshipId);
                let scholarship = response.data.scholarship;
                this.setState({
                    shift: scholarship.shift,
                    type: scholarship.type,
                    full_price: scholarship.full_price,
                    full_price_float: scholarship.full_price,
                    discount: scholarship.discount,
                    quantity_offered: scholarship.quantity_offered,
                    renovation_days: scholarship.renovation_days,
                    register_period_start: new Date(scholarship.register_period_start),
                    register_period_end: scholarship.register_period_end ? new Date(scholarship.register_period_end) : null,
                    course_description: scholarship.course_description,
                    course_id: scholarship.course_id,
                    institution_id: scholarship.institution_id,
                });

            } catch (error) {
                console.log(error)
            }
        }
        this.startSelect2();
    }

    handleClose = () => {
        this.clearForm();
        if (this.props.onRequestClose) this.props.onRequestClose();
    }

    clearForm = () => {
        this.setState({
            shift: "",
            type: "",
            full_price: null,
            full_price_float: null,
            discount: "",
            quantity_offered: "",
            renovation_days: "",
            register_period_start: new Date(),
            register_period_end: null,
            course_description: "Descrição do curso",
            course_id: "",
            institution_id: "",
        })
    }

    handleDateChange = (value, type) => {
        if (type === 'start') this.setState({ register_period_start: value })
        else if (type === 'end') this.setState({ register_period_end: value })
    }

    handleFullPriceChange = (event, maskedvalue, floatvalue) => {
        this.setState({ full_price: maskedvalue, full_price_float: floatvalue });
    }

    handleCoursePeriodChange = (input) => {
        // Remove todos os caracteres que não são números
        const onlyNumbers = input.replace(/\D/g, '').slice(0, 5);
        // Se a quantidade de números for 5, insere o ponto entre o quarto e o quinto caractere
        if (onlyNumbers.length === 5) {
          return `${onlyNumbers.slice(0, 4)}.${onlyNumbers.slice(4)}`;
        }
        return onlyNumbers; // Retorna os números sem o ponto se ainda não chegou a 5 caracteres
      }
      

    render() {
        const { institutions, courses } = this.state;
        return (
            <ReactModal
                {...this.props}
                style={customStyles}
                onAfterOpen={this.startModal}
                onRequestClose={this.handleClose}
                onAfterClose={this.clearForm}
            >
                <div className="modal-header border-bottom d-flex justify-content-between align-items-center">
                    <h5>Cadastrar nova bolsa</h5>
                    <i className="fas fa-times close-icon p-2" onClick={this.props.onRequestClose}></i>
                </div>

                <div className="custom-modal-body px-3 pt-2 pb-0">
                    <form id="scholarship-form" onSubmit={e => { this.handleSubmit(e) }}>
                        <div className="form-group">
                            <label>Curso</label>
                            <select
                                className="form-control course-select"
                                name="course_id"
                                value={this.state.course_id}
                                onChange={e => { this.setState({ course_id: e.target.value }) }}
                                disabled={this.props.scholarshipId ? true : false}
                                required
                            >
                                <option disabled value="disabled">Selecionar curso</option>
                                {courses.map(course => (
                                    <option key={course.id} value={course.id}>{course.name} - {course.category.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Instituição</label>
                            <select
                                className="form-control institution-select"
                                name="institution_id"
                                value={this.state.institution_id}
                                onChange={e => { this.setState({ institution_id: e.target.value }) }}
                                disabled={this.props.scholarshipId ? true : false}
                                required
                            >
                                <option disabled value="disabled">Selecionar instituição</option>
                                {institutions.map(institution => (
                                    <option key={institution.id} value={institution.id}>{institution.name} - {institution.city}</option>
                                ))}
                            </select>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-sm-6 pr-sm-2">
                                <label>Tipo</label>
                                <select
                                    className="form-control"
                                    name="type"
                                    value={this.state.type}
                                    onChange={e => { this.setState({ type: e.target.value }) }}
                                    required
                                >
                                    <option value="presencial">Presencial</option>
                                    <option value="semi-presencial">Semi presencial</option>
                                    <option value="ead">EAD</option>
                                </select>
                            </div>
                            <div className="col-sm-6 pl-sm-2">
                                <label>Turno</label>
                                <select
                                    className="form-control"
                                    name="shift"
                                    value={this.state.shift}
                                    onChange={e => { this.setState({ shift: e.target.value }) }}
                                    required
                                >
                                    <option disabled value="">Selecionar turno</option>
                                    <option value="matutino">Matutino</option>
                                    <option value="vespertino">Vespertino</option>
                                    <option value="noturno">Noturno</option>
                                    <option value="integral">Integral</option>
                                    <option value="segundas-manha">Segundas - (Manhã)</option>
                                    <option value="segundas-noite">Segundas - (Noite)</option>
                                    <option value="segundas-tercas-manha">Segundas e terças - (Manhã)</option>
                                    <option value="segundas-tercas-tarde">Segundas e terças - (Tarde)</option>
                                    <option value="segundas-tercas-noite">Segundas e terças - (Noite)</option>
                                    <option value="segundas-tercas-manha-tarde">Segundas e terças - (Manhã e tarde)</option>
                                    <option value="tercas-manha">Terças - (Manhã)</option>
                                    <option value="tercas-tarde">Terças - (Tarde)</option>
                                    <option value="tercas-noite">Terças - (Noite)</option>
                                    <option value="tercas-quintas-noite">Terças e quintas - (Noite)</option>
                                    <option value="quartas-manha">Quartas - (Manhã)</option>
                                    <option value="quartas-tarde">Quartas - (Tarde)</option>
                                    <option value="quartas-noite">Quartas - (Noite)</option>
                                    <option value="quartas-sextas-noite">Quartas e sextas - (Noite)</option>
                                    <option value="quartas-manha-tarde">Quartas - (Manhã e tarde)</option>
                                    <option value="quintas-manha">Quintas - (Manhã)</option>
                                    <option value="quintas-tarde">Quintas - (Tarde)</option>
                                    <option value="quintas-noite">Quintas - (Noite)</option>
                                    <option value="sextas-manha">Sextas - (Manhã)</option>
                                    <option value="sextas-tarde">Sextas - (Tarde)</option>
                                    <option value="sextas-noite">Sextas - (Noite)</option>
                                    <option value="sabados-manha">Sábados - (Manhã)</option>
                                    <option value="sabados-tarde">Sábados - (Tarde)</option>
                                    <option value="sabados-manha-tarde">Sábados - (Manhã e tarde)</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group editor-container mt-3">
                            <label>Descrição</label>
                            <ReactQuill
                                value={this.state.course_description}
                                onChange={value => { this.setState({ course_description: value }) }}
                                modules={{ toolbar: toolbarOptions }}
                            />
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col-12">
                                <label>Datas do período de matrícula</label>
                            </div>
                            <div className="col-sm-6 pr-sm-2">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.register_period_start}
                                    className="form-control d-flex"
                                    placeholderText="Inicio das matrículas"
                                    onChange={value => { this.setState({ register_period_start: value }) }}
                                    required
                                />
                            </div>
                            <div className="col-sm-6 pl-sm-2">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.register_period_end}
                                    className="form-control d-flex"
                                    placeholderText="Fim das matrículas"
                                    onChange={value => { this.setState({ register_period_end: value }) }}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col-12">
                                <label>período</label>
                            </div>
                            <div className="col-sm-12 pr-sm-2">
                                <div className='row '>
                                    {this.state.course_period.map((period, index) => (
                                        <div className={`d-flex mt-2 col-sm-6 ${index % 2 === 0 ? 'pr-sm-2' : 'pl-sm-2'}`} key={index}>
                                            <input
                                                type="text"
                                                className='form-control'
                                                name="course_period"
                                                value={period.value}
                                                onChange={e => {
                                                    let periods = this.state.course_period;
                                                    const periodFormatted = this.handleCoursePeriodChange(e.target.value);
                                                    console.log(periodFormatted)
                                                    periods[index].value = periodFormatted;
                                                    this.setState({ course_period: periods })
                                                }}
                                                placeholder="Ex: 2025.1"
                                                required
                                            />
                                        </div>
                                    ))}
                                </div>
                               
                                <div className="d-flex p-0 col-sm-12 justify-content-start mt-2">
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        let periods = this.state.course_period;
                                        periods.push({ value: "" });
                                        this.setState({ course_period: periods });
                                    }}>Adicionar período</button>

                                    {this.state.course_period.length > 1 && (
                                        <button type="button" className="btn btn-secondary ml-2" onClick={() => {
                                            let periods = this.state.course_period;
                                            periods.pop();
                                            this.setState({ course_period: periods });
                                        }}>Remover período</button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col-6">
                                <label>Valor</label>
                            </div>
                            <div className="col-6 pl-2">
                                <label>Desconto</label>
                            </div>
                            <div className="col-sm-6 pr-sm-2">
                                <CurrencyInput
                                    value={this.state.full_price}
                                    onChangeEvent={this.handleFullPriceChange}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix="R$"
                                    className="form-control"
                                    placeholder="Valor integral"
                                    required
                                />
                            </div>
                            <div className="col-sm-6 pl-sm-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="discount"
                                    value={this.state.discount}
                                    onChange={e => { this.setState({ discount: e.target.value }) }}
                                    placeholder="Ex: 50%"
                                    required
                                />
                            </div>
                        </div>
                        <div className="row no-gutters mt-3">
                            <div className="col-6">
                                <label>Dias para renovação</label>
                            </div>
                            <div className="col-6 pl-2">
                                <label>Bolsas ofertadas</label>
                            </div>
                            <div className="col-6 pr-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="renovation_days"
                                    value={this.state.renovation_days}
                                    onChange={e => { this.setState({ renovation_days: e.target.value }) }}
                                    placeholder="Ex: 180"
                                    required
                                />
                            </div>
                            <div className="col-6 pl-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="quantity_offered"
                                    value={this.state.quantity_offered}
                                    onChange={e => { this.setState({ quantity_offered: e.target.value }) }}
                                    placeholder="Ex: 5"
                                    required
                                />
                            </div>
                        </div>
                        <div className="custom-modal-footer border-top d-flex justify-content-end align-items-center p-3">
                            <button type="button" className="btn btn-secondary mr-3" onClick={this.handleClose}>Cancelar</button>
                            <button type="submit" className="btn btn-primary">Salvar</button>
                        </div>
                    </form>
                </div>
            </ReactModal>
        )
    }
}