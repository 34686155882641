import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import Routes from './Routes';

import { ToastContainer } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';

import './global.css';
import Notifications from './components/Notifications';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [modalNotificationsOpen, setModalNotificationsOpen] = useState(false);

  useEffect(() => {
    // Check for HTTP and redirect to HTTPS
    if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    } else {
      setLoading(false);
    }
  }, []);

  const toggleModal = () => {
      setModalNotificationsOpen(!modalNotificationsOpen);
  }

  const handleLogout = () => {
    localStorage.clear();
    setLogout(true);
  };

  if (loading) {
    return <div>Verificando</div>;
  }

  if (logout) {
    return <Redirect to="/login" />;
  }

  return (
    <Router>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom d-flex justify-content-between">
            <button
              className="btn btn-primary"
              onClick={(e) =>
                document.getElementById('wrapper').classList.toggle('toggled')
              }
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className="d-flex align-items-center">
              <button className="btn btn-link" onClick={toggleModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-bell"><path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"/><path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"/></svg>
              </button>
              <button className="btn btn-link" onClick={handleLogout}>
                Sair
              </button>
            </div>
          </nav>
          <div className="container-fluid">
            <Routes />
          </div>
        </div>
        <ToastContainer />
      </div>
      <Notifications
        isOpen={modalNotificationsOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={toggleModal}
      />
    </Router>
  );
};

export default App;
