import React, { Component } from 'react';

import Api from '../../Services/Cloud';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';

import '../../Libs/fontawesome/css/all.css';

import $ from 'jquery';
import CallModal from './CallModal';
import { toast } from 'react-toastify';
import moment from 'moment';

const columns = [
    { title: "Indicado por", data: 'user.name' },
    { title: "Nome", data: 'name' },
    { title: "E-mail", data: 'email' },
    { title: "Cidade", data: 'city' },
    { title: "Telefone", data: 'cell' },
    {
        title: 'Ligações',
        render: (data, type, row) => {
            let string = '<div class="my-2">';
            row.CallIndications.forEach(call => {
                string += `<div class="text-wrap width-200"><b>${moment(call.createdAt).format('DD/MM/YYYY hh:mm')}</b> - ${call.caller.name} - ${call.description}</div>`
            });
            string += '</div>';
            return string;
        },
    },
    { title: 'Ações' },
];

const columnDefs = [
    {
        targets: -1,
        data: null,
        defaultContent: '<a class=" action-button btn-link"><span class="text-primary btn-register-call">Cadastrar ligação</span></a>'
    }
];

export default class Indications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            indications: [],
            showCallModal: false,
            loading: true
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        let response = await Api.get('/indications');
        console.log(response.data.indications)
        this.setState({ indications: response.data.indications, loading: false });
    }

    toggleCallModal = (possiblePartinerId) => {
        if (this.state.showCallModal) {
            this.setState({ possiblePartinerId: null });
        }
        this.setState({ possiblePartinerId, showCallModal: !this.state.showCallModal })
    }

    handleTableClick = (e, dataTable) => {
        let target = $(e.target);
        var current_row = target.parents('tr');
        if (current_row.hasClass('child')) {
            current_row = current_row.prev();
        }
        var data = dataTable.row(current_row).data();
        if (e.target.className.includes('btn-register-call')) {
            this.toggleCallModal(data.id)
        }
    }

    handleSuccess = (message) => {
        this.setState({ showCallModal: false, showCalleds: false, showToCall: false, loading: true });
        this.getData(true);
        toast.success(message);
    }

    handleError = (message) => {
        toast.error(message);
    }

    render() {
        if (this.state.loading) return <Loading />;
        return (
            <div className="mt-2 w-100">
                <DataTable
                    data={this.state.indications}
                    columns={columns}
                    options={{
                        buttons: [],
                        responsive: true,
                        columnDefs: columnDefs,
                        destroy: true
                    }}
                    handleActionClick={(e, dataTable) => { this.handleTableClick(e, dataTable) }}
                    ref={ref => this.table = ref}
                />
                <CallModal
                        isOpen={this.state.showCallModal}
                        shouldCloseOnEsc={true}
                        possiblePartinerId={this.state.possiblePartinerId}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={this.toggleCallModal}
                        onSuccess={message => { this.handleSuccess(message) }}
                        onError={message => { this.handleError(message) }}
                    />
            </div>
        )
    }
}
